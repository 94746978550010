import React from 'react';
import { graphql } from 'gatsby';

import { InsightsPageTemplate } from '../templates';
import { Layout } from '../components';

const InsightsPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark;
    const content = data.allMarkdownRemark.edges.map(({ node }) => node);
    return (
        <Layout title={ frontmatter.headerTitle }>
            <InsightsPageTemplate { ...frontmatter} content={ content }/>
        </Layout>
    );
};

export default InsightsPage;

export const pageQuery = graphql`
    query InsightsPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "insights-page" } }) {
            frontmatter {
                headerTitle
                headerImage {
                    assetType
                    cloudinaryMedia
                    deliveryType
                    sourceURL
                    urlRemainder
                }
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "insights-content" } } },
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    fileAbsolutePath
                    frontmatter {
                        title
                        paragraph1
                        date
                    }
                }
            }
        }
    }
`;